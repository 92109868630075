import { gql } from "@outschool/ui-apollo";
import { ActivityListingFragment } from "@outschool/ui-components-website";

export const classInquiriesByUserQuery = gql`
  query ClassInquiriesByUser($userUid: ID!) {
    getClassInquiriesByUser(userUid: $userUid) {
      uid
      status
    }
  }
`;

export const classInquiriesForTeacherQuery = gql`
  query ClassInquiriesForTeacher($teacherUid: ID!, $isActive: Boolean) {
    getTeacherClassInquiries(teacherUid: $teacherUid, isActive: $isActive) {
      teacherRepliedCountToday
      classInquiriesWithLearnerAges {
        uid
        status
        inquiry_text
        learner_age
        created_at
      }
      teacherLastReplyMessage
    }
  }
`;

export const teacherHasInquiriesButHasNotRepliedTodayQuery = gql`
  query TeacherHasInquiriesButHasNotRepliedToday(
    $teacherUid: ID!
    $isActive: Boolean
  ) {
    getTeacherClassInquiries(teacherUid: $teacherUid, isActive: $isActive) {
      teacherHasInquiriesButHasNotRepliedToday
    }
  }
`;

export const requestClassInquiryMutation = gql`
  mutation RequestClassInquiry(
    $userUid: ID!
    $inquiryText: String!
    $learnerUid: ID
    $searchFilters: SearchFilters
    $skipRating: Boolean = false
    $isV2_5Treatment: Boolean
  ) {
    requestClassInquiry(
      userUid: $userUid
      inquiryText: $inquiryText
      learnerUid: $learnerUid
      searchFilters: $searchFilters
      isV2_5Treatment: $isV2_5Treatment
    ) {
      classInquiries {
        uid
        status
      }
      topRecommendedActivityListing {
        ...ActivityListing_listing
      }
      searchPath
      followUpQuestion
    }
  }
  ${ActivityListingFragment}
`;

export const sendFollowUpQuestionMutation = gql`
  mutation SendFollowUpQuestion(
    $classInquiryUid: ID!
    $conversation: [ChatConversationMessageInput]!
    $skipRating: Boolean = false
  ) {
    sendFollowUpQuestion(
      classInquiryUid: $classInquiryUid
      conversation: $conversation
    ) {
      topRecommendedActivityListing {
        ...ActivityListing_listing
      }
      searchPath
      followUpQuestion
    }
  }
  ${ActivityListingFragment}
`;

export const replyParentRequestMutation = gql`
  mutation ReplyParentRequest(
    $teacherUid: ID!
    $classInquiryUid: ID!
    $relevantActivityUid: ID!
    $replyText: String!
  ) {
    replyClassInquiry(
      teacherUid: $teacherUid
      classInquiryUid: $classInquiryUid
      relevantActivityUid: $relevantActivityUid
      replyText: $replyText
    ) {
      uid
      reply_message_uid
    }
  }
`;

export const softDeleteTeacherClassInquiryMutation = gql`
  mutation SoftDeleteTeacherClassInquiry(
    $teacherUid: ID!
    $classInquiryUid: ID!
  ) {
    softDeleteTeacherClassInquiry(
      teacherUid: $teacherUid
      classInquiryUid: $classInquiryUid
    ) {
      uid
      deleted_at
    }
  }
`;
