import { Alert, AlertActionButton, Box, Theme } from "@outschool/backpack";
import {
  TeacherHasInquiriesButHasNotRepliedTodayQuery,
  TeacherHasInquiriesButHasNotRepliedTodayQueryVariables
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import {
  forSellerOrgPath,
  sellerOrgPath,
  teachPath,
  teacherDashboardPath
} from "@outschool/routes";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { useNavigation } from "@outschool/ui-utils";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router";

import { teacherHasInquiriesButHasNotRepliedTodayQuery } from "../../queries/ClassInquiryQueries";

export function ParentRequestsBannerWrapper() {
  const { currentUser } = useSession();
  const { pathname } = useLocation();

  const isUserSellerOrgAdmin = !!currentUser?.sellerOrg?.currentUserIsOwner;
  const isUserIndependentTeacher =
    !currentUser?.sellerOrg?.currentUserIsTeacher &&
    !!currentUser?.teacher_approved_at;

  const isNonDashboardTeachPath =
    pathname.startsWith(teachPath()) &&
    !pathname.includes(teacherDashboardPath());
  const isSellerOrgPath =
    pathname.startsWith(sellerOrgPath()) ||
    pathname.startsWith(forSellerOrgPath());

  // Parent requests are sent to seller org admins and independent teachers but NOT non-admin seller org teachers
  const canShowBanner =
    (isUserSellerOrgAdmin || isUserIndependentTeacher) &&
    (isNonDashboardTeachPath || isSellerOrgPath);

  if (!canShowBanner) {
    return null;
  }

  return <ParentRequestsBanner />;
}

function ParentRequestsBanner() {
  const { t } = useTranslation(
    "client\\components\\teacher\\ParentRequestsBanner"
  );
  const { currentUser } = useSession();
  const navigate = useNavigation();

  const [isClosed, setIsClosed] = useState(false);

  const { loading, data } = useQueryWithPreviousData<
    TeacherHasInquiriesButHasNotRepliedTodayQuery,
    TeacherHasInquiriesButHasNotRepliedTodayQueryVariables
  >(teacherHasInquiriesButHasNotRepliedTodayQuery, {
    variables: {
      teacherUid: currentUser?.uid ?? "",
      isActive: true
    },
    fetchPolicy: "network-only"
  });

  const onClose = useCallback(() => {
    setIsClosed(true);
  }, []);

  const onClick = useCallback(() => {
    navigate(teacherDashboardPath());
  }, [navigate]);

  if (
    loading ||
    !data?.getTeacherClassInquiries.teacherHasInquiriesButHasNotRepliedToday ||
    isClosed
  ) {
    return null;
  }

  return (
    <Box
      flex
      sx={(theme: Theme) => ({
        justifyContent: "center",
        margin: "2rem",
        [theme.breakpoints.down("md")]: {
          margin: "1rem"
        }
      })}
    >
      <Alert
        children={t(
          "There are new parent requests, please review and respond to those you think are a good fit."
        )}
        severity="info"
        action={
          <AlertActionButton onClick={onClick}>
            {t("View Requests")}
          </AlertActionButton>
        }
        onClose={onClose}
        sx={{
          maxWidth: 1255
        }}
      ></Alert>
    </Box>
  );
}
